const contentData = [
  {
    title: "Generar avisos",
    link: "/avis",
    description:
      "Permet generar un avís des d'on sigui, i automàticament es sincronitza amb el Wingest de TECMAN."
  },
  {
    title: "Donar clients d'alta",
    link: "/altaclient",
    description:
      "Es poden donar d'alta clients nous i que aquests es quedin registrats al Wingest."
  },
  {
    title: "Consultar estat avisos",
    link: "/veureavisos",
    description:
      "Es pot consultar l'estat dels avisos generats per vosaltres mateixos des d'aquesta mateixa aplicació"
  },
  {
    title: "Consultar i reservar stock de material",
    link: "/consulta",
    description:
      "Permet consultar i reservar stock de material, sincronitzat instantàniament amb el Wingest"
  }
];

export default contentData;
