import React from 'react';
import { MDBInput, MDBRow, MDBCol } from 'mdb-react-ui-kit';

export default function Avis() {
  return (
    <>
      <MDBRow>
        <MDBCol>
          <MDBInput id='nomClient' label='Nom' />
        </MDBCol>
        <MDBCol>
          <MDBInput id='cognomsClient' label='Cognoms' />
        </MDBCol>
        <MDBCol>
          <MDBInput id='emailClient' label='Email' type='email' />
        </MDBCol>
      </MDBRow>

      <hr />

      <MDBRow>
        <MDBCol>
          <MDBInput id='telefonClient' label='Telèfon' type='tel' />
        </MDBCol>
        <MDBCol>
          <MDBInput id='adrecaClient' label='Adreça' />
        </MDBCol>
        <MDBCol>
          <MDBInput id='form10Example5' label='Email address' type='email' />
        </MDBCol>
      </MDBRow>
    </>
  );
}